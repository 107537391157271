import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import useForm from '../components/form/Form'
import TextInput from '../components/form/TextInput';
import SelectInput from '../components/form/Select';
import productOptions from '../data/options/productOptions';
import creamColors from '../data/options/creamColors';
import events from '../data/options/events';
import { Link } from 'react-router-dom';

function ReservationForm() {
  const [formData, handleChange] = useForm({
    name: '',
    phoneNumber: '',
    creamColor: '',
    product: '',
    premiumCharm: '',
    eventName: ''
  });

  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState('');

  const formatPhoneNumber = (value) => {
    // Remove all non-digits
    const numbers = value.replace(/[^\d]/g, '');
    // Limit to 10 digits
    const phoneNumber = numbers.substring(0, 10);
    // Split into parts
    const part1 = phoneNumber.substring(0, 3);
    const part2 = phoneNumber.substring(3, 6);
    const part3 = phoneNumber.substring(6, 10);

    // Combine parts with dashes
    return `${part1}${part2 ? '-' : ''}${part2}${part3 ? '-' : ''}${part3}`.trim();
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data before submission:', formData);

    const endpointUrl = process.env.REACT_APP_API_ENDPOINT;

    if (!endpointUrl) {
      console.error('Azure Logic App endpoint URL is not defined in the environment variables.');
      return;
    }

    fetch(endpointUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        setModalContent(`${data.result} Total Cost: $${data.totalCost}`);
        setShowModal(true);
      })
      .catch(error => console.error('Error during form submission:', error));
  };


  return (
    <div className='pageComponent'>
      <div className='title'><h1 className='subtitle'>Add to Queue</h1></div>
      <br></br>
      <Link to="/dwd"> <Button variant="outline-primary">System Home</Button></Link>{' '}
      <div className='content'>
        <form onSubmit={handleSubmit}>
          <SelectInput
            name="eventName"
            label="Event"
            value={formData.eventName}
            onChange={handleChange}
            options={events}
            required
          />
          <TextInput name="name" label="Name" placeholder="Name of Participant" value={formData.name} onChange={handleChange} required/>
          <TextInput
            name="phoneNumber"
            label="Phone Number"
            placeholder="Phone Number for SMS"
            value={formData.phoneNumber}
            onChange={(e) => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              handleChange({ target: { name: e.target.name, value: formattedPhoneNumber } });
            }}
            required
          />
          <SelectInput
            name="creamColor"
            label="Cream Color"
            value={formData.creamColor}
            onChange={handleChange}
            options={creamColors}
          />
          <SelectInput
            name="product"
            label="Item to Decorate"
            value={formData.product}
            onChange={handleChange}
            options={productOptions}
            required
          />
          <TextInput
            name="premiumCharm"
            label="Premium Charms"
            placeholder="Number of Premium Charms Purchased"
            value={formData.premiumCharm}
            onChange={(e) => {
              // Check if the input value is empty or explicitly zero; if so, default to "0"
              const value = e.target.value === '' || e.target.value === '0' ? '0' : e.target.value;
              handleChange({ target: { name: e.target.name, value } });
            }}
          />
          <Button variant="primary" type="submit">Submit</Button>{' '}<Link to="/dwd"> <Button variant="outline-primary">System Home</Button></Link>{' '}
        </form>

      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reservation Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModal(false); window.location.reload(); }}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
    </div>

  );
}

export default ReservationForm;
