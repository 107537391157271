import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Seats = () => {
    const [seats, setSeats] = useState([]);
    const [showModal, setShowModal] = useState(false); // To control modal visibility
    const [modalMessage, setModalMessage] = useState(''); // To store the modal message

    const fetchSeats = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SEATS);
            setSeats(response.data); // Assuming the response data is the seats list
        } catch (error) {
            console.error('Failed to fetch seating chart:', error);
        }
    };

    const readableDate = (isoDate) => {
        return moment(isoDate).format('MMMM DD, YYYY, hh:mm A');
    };

    const handleActionClick = async (item, actionType) => {
        const endpoint = process.env.REACT_APP_API_ENDPOINT_SEATS_MANAGE;

        const payload = {
            "Action": actionType,
            "CreamColor": item.CreamColor,
            "EventName": item.EventName,
            "ID": item.ID, // Ensure items have an ID attribute; adjust if necessary
            "Item": item.Item,
            "Name": item.Name,
            "PhoneNumber": item.PhoneNumber,
            "TimeAdded": item.TimeAdded
        };

        try {
            const response = await axios.post(endpoint, payload);
            if (response.status === 200) {
                if (actionType === 'done') {
                    fetchSeats(); // Refresh the seats list
                } else if (actionType === 'waitlist') {
                    setModalMessage(response.data.status); // Assuming the response object has a 'message' field
                    setShowModal(true); // Show the modal with the status message
                    fetchSeats(); // Refresh the seats list for 'waitlist' as well
                }
            } else {
                console.error('Response received, but status is not 200:', response);
            }
        } catch (error) {
            console.error(`Failed to perform action for item ${item.ID}:`, error);
        }
    };

    return (
        <div className='pageComponent'>
            <div className='title'><h1 className='subtitle'>Seats</h1></div>
            <br></br>
            <Button variant='outline-primary' onClick={fetchSeats} className='refreshButton'>Refresh Seating Chart</Button>
            <Link to="/dwd"><Button variant="outline-primary">System Home</Button></Link>
            <div className='content'>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Item</th>
                            <th>Cream Color</th>
                            <th>Time Added</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {seats.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Name}</td>
                                <td>{item.Item}</td>
                                <td>{item.CreamColor}</td>
                                <td>{readableDate(item.TimeAdded)}</td>
                                <td>
                                    <Button variant="outline-success" onClick={() => handleActionClick(item, 'waitlist')}>Waitlist</Button>{' '}
                                    <Button variant="outline-danger" onClick={() => handleActionClick(item, 'done')}>Done</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Seats;
