// productOptions.js
const productOptions = [
    { 
      groupLabel: "Hair Clips", 
      options: [
        { label: "Bubble Hair Clip", value: "bubbleHairClip" },
        { label: "Helix Hair Clip", value: "helixHairClip" },
        { label: "Rectangle Hair Clip", value: "rectangleHairClip" },
        { label: "Small Hair Clip", value: "smallHairClip" },
      ]
    },
    { 
      groupLabel: "iPhone Cases", 
      options: [
        { label: "iPhone 13", value: "iphone13" },
        { label: "iPhone 13 Pro", value: "iphone13Pro" },
        { label: "iPhone 13 Max", value: "iphone13Max" },
        { label: "iPhone 14", value: "iphone14" },
        { label: "iPhone 14 Pro", value: "iphone14Pro" },
        { label: "iPhone 14 Max", value: "iphone14Max" },
        { label: "iPhone 15", value: "iphone15" },
        { label: "iPhone 15 Pro", value: "iphone15Pro" },
        { label: "iPhone 15 Max", value: "iphone15Max" },
      ]
    },
    { 
      groupLabel: "Jewelry Box", 
      options: [
        { label: "Blue Jewelery Box", value: "blueJewelryBox" },
        { label: "Pink Jewelery Box", value: "pinkJewelryBox" },
        { label: "Purple Jewelery Box", value: "purpleJewelryBox" },
        { label: "White Jewelery Box", value: "whiteJewelryBox" },
      ]
    },
    { 
      groupLabel: "Keychains", 
      options: [
        { label: "Heart Keychain", value: "heartKeychain" },
        { label: "Round Keychain", value: "roundKeychain" },
      ]
    },
    { 
      groupLabel: "Mirrors", 
      options: [
        { label: "Heart Compact Mirror", value: "heartCompact" },
        { label: "Round Compact Mirror", value: "roundCompact" },
        { label: "Mirror with Base", value: "mirrorBase" },
      ]
    },
    { 
      groupLabel: "Photo Frames", 
      options: [
        { label: "Fridge Photo Frame", value: "fridgeFrame" },
        { label: "Photo Frame", value: "photoFrame" },
      ]
    },
  ];
  
  export default productOptions;

