import { Form } from 'react-bootstrap';

function SelectInput({ label, options, value, onChange, name }) {
  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Select name={name} value={value} onChange={onChange}>
        <option value="" disabled>{label}</option> {/* Placeholder option */}
        {options.map((group, index) => (
          <optgroup label={group.groupLabel} key={index}>
            {group.options.map(opt => (
              <option value={opt.value} key={opt.value}>{opt.label}</option>
            ))}
          </optgroup>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default SelectInput;
