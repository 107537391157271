import React from 'react';
import { Container } from 'react-bootstrap';
import SocialMediaHandles from './social/Handles';


function About() {
    const tiktokHandle = 'decoponatx';
    const instagramHandle = 'decoponatx';

    return (
        <Container>
        <div className='contact'>
            <div className='title'>
                <h1 className='subtitle'>Contact Us</h1>
            </div>
            <div className='content'>
                Get ready to unleash your creativity with Decopon (deh-koh-pon)! Explore our decoden kits for a unique DIY adventure at home or join us in person at your local artisan/craft fair in Austin, TX. We also provide party packages for special celebrations, such as birthdays and team building events. 
                <br></br>
                <br></br>
                If you have any questions or need assistance, please don't hesitate to
                contact us. Send an email (hello@decoponatx.com) or DM us on IG/TikTok.
                <br></br>
                <br></br>
                <SocialMediaHandles
          tiktokHandle={tiktokHandle}
          instagramHandle={instagramHandle}></SocialMediaHandles>
            </div>
        </div>
        </Container>
    );
}

export default About;
