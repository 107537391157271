const events = [
    { 
      groupLabel: "May 2024", 
      options: [
        { label: "Bee Cave Aristan Market May 2024", value: "beeCaveArtisanMay2024" },
      ]
    },
  ];
  
  export default events;

