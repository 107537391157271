const productOptions = [
    {
        groupLabel: "Colors",
        options: [
            { label: "Blue", value: "blue" },
            { label: "Blue/White", value: "blueWhite" },
            { label: "Cotton Candy", value: "cottonCandy" },
            { label: "Lavender", value: "lavender" },
            { label: "Lavender/White", value: "lavenderWhite" },
            { label: "Mint Green", value: "mintGreen" },
            { label: "Mint Green/White", value: "mintGreenWhite" },
            { label: "Pink", value: "pink" },
            { label: "Pink/White", value: "pinkWhite" },
            { label: "Sherbert", value: "sherbert" },
            { label: "Twilight", value: "twilight" },
            { label: "White", value: "white" },
            { label: "Yellow", value: "yellow" },
            { label: "Yellow/White", value: "yellowWhite" },
            { label: "Citron", value: "citron" }
        ]
    },
];

export default productOptions;

