import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

function MarketSystem() {
  return (
    <div className='pageComponent'>
      <div className='title'><h1 className='subtitle'>Manage Market Queue</h1></div>
      <div className='content' style={{textAlign: 'center'}}>
        <Link to='/r'>
            <Image className='buttonSystem' src='https://files.decoponatx.com/img/reserve.png'>
            </Image>
        </Link>
        {/* <Link to='/tg'>
        <Image className='buttonSystem' src='https://files.decoponatx.com/img/togo.png'>
            </Image>
        </Link> */}
        <Link to="/s"> 
        <Image className='buttonSystem' src='https://files.decoponatx.com/img/seats.png'>
            </Image>
        </Link>
        <Link to="/w"> 
        <Image className='buttonSystem' src='https://files.decoponatx.com/img/waitlist.png'>
            </Image>
        </Link>
        {/* <Link to="/t">
        <Image className='buttonSystem' src='https://files.decoponatx.com/img/togo-m.png'>
            </Image>
        </Link> */}
      </div>
    </div>
  );
}

export default MarketSystem;
