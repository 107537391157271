import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import eventsData from '../data/json/events.json';
import 'add-to-calendar-button-react';


const EventList = ({ events }) => {
    const [sortedEvents, setSortedEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        // Filter and sort events by date
// Filter and sort events by date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const filteredEvents = events
    .filter((event) => new Date(event.date) >= yesterday)
    .sort((a, b) => new Date(a.date) - new Date(b.date));

setSortedEvents(filteredEvents);
}, [events]);

    const openMapModal = (event) => {
        if (event.googleMap) {
            setSelectedEvent(event);
        }
    };

    const closeMapModal = () => {
        setSelectedEvent(null);
    };

    return (
        <Container>
            <Row className='component'>
                <Col className='title'>
                    <h1 className='subtitle'>Upcoming Events</h1>
                </Col>
                <Row className='content'>
                    <Col xs={12} className='eventTableRow eventDate'>
                        <Col className='eventTableCell'>
                            <strong>Title</strong>
                        </Col>
                        <Col className='eventTableCell'>
                            <strong>Date</strong>
                        </Col>
                    </Col>
                    {sortedEvents.map((event) => (
                        <Col
                            key={event.id}
                            xs={12}
                            className={`eventTableRow ${event.googleMap ? 'clickableEvent' : ''
                                }`}
                            onClick={() => openMapModal(event)}
                        >
                            <Col className='eventTableCell'>{event.title}</Col>
                            <Col className='eventTableCell'>{event.date}</Col>
                        </Col>
                    ))}
                </Row>
            </Row>

            <MapModal event={selectedEvent} onClose={closeMapModal} />
        </Container>
    );
};

const AddToCalendarButtonComponent = ({ event }) => {
    if (!event) return null;

    return (
        <add-to-calendar-button
            name={event.desc}
            startDate={event.date}
            startTime={event.startTime} 
            endTime={event.endTime}
            timeZone="America/Chicago"
            location={event.address}
            options="iCal"
            lightMode="bodyScheme"
            hideCheckmark={true}
            hideBackground={true}
            buttonStyle="round"
            iCalFileName="decopon-event"
            styleLight="--btn-background: #FEA751; --btn-text: #fff; --font: 'SlowRiver', sans-serif;"
        />
    );
};

const MapModal = ({ event, onClose }) => {
    return (
        <Modal show={!!event} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Event Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {event && (
                    <>
                        <div className='event-details'>
                            <h4>{event.title}</h4>
                            <p>Location: <a href={event.googleMap} target="_blank" rel="noreferrer">{event.location}</a></p>
                            <p>Time: {event.startTime} - {event.endTime}</p>
                            <p>More Info: <a href={event.url} target='_blank' rel='noreferrer'>{event.url}</a></p>

                        </div>
                        <div>
                            <AddToCalendarButtonComponent event={event} />
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

const Events = () => {
    return <EventList events={eventsData} />;
};

export default Events;
