import { Form } from 'react-bootstrap';

function TextInput({ label, value, onChange, ...props }) {
  return (
    <Form.Group className="mb-3" controlId={props.name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control type="text" value={value} onChange={onChange} {...props} />
    </Form.Group>
  );
}

export default TextInput;