import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Waitlist = () => {
    const [waitlist, setWaitlist] = useState([]);
    const [showModal, setShowModal] = useState(false); // To control modal visibility
    const [modalMessage, setModalMessage] = useState(''); // To store the modal message

    const fetchWaitlist = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_ENDPOINT_WAIT);
            setWaitlist(response.data); // Assuming the response data is the waitlist
        } catch (error) {
            console.error('Failed to fetch waitlist:', error);
        }
    };

    const readableDate = (isoDate) => {
        return moment(isoDate).format('MMMM DD, YYYY, hh:mm A');
    };

    const handleActionClick = async (item, actionType) => {
        const endpoint = process.env.REACT_APP_API_ENDPOINT_WAIT_MANAGE; // Your API endpoint for actions

        const payload = {
            "Action": actionType,
            "CreamColor": item.CreamColor,
            "EventName": item.EventName,
            "ID": item.ID, // Ensure items have an ID attribute; adjust if necessary
            "Item": item.Item,
            "Name": item.Name,
            "PhoneNumber": item.PhoneNumber,
            "TimeAdded": item.TimeAdded
        };

        try {
            const response = await axios.post(endpoint, payload);
            // Assuming response.data contains a status message field for simplification
            if (response.status === 200) {
              setModalMessage(response.data.status); 
              setShowModal(true);
              fetchWaitlist();
            } else {
              console.error('Response received, but status is not 200:', response);
            }
          } catch (error) {
            console.error(`Failed to perform action for item ${item.ID}:`, error);
          }
    };


    return (
        <div className='pageComponent'>
            <div className='title'><h1 className='subtitle'>Waitlist</h1></div>
            <br></br>
            <Button variant='outline-primary' onClick={fetchWaitlist} className='refreshButton'>Refresh Waitlist</Button>{' '}<Link to="/dwd"> <Button variant="outline-primary">System Home</Button></Link>{' '}
            <div className='content'>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Item</th>
                            <th>Cream Color</th>
                            <th>Time Added</th>
                            <th>Actions</th> {/* Placeholder for future implementation */}
                        </tr>
                    </thead>
                    <tbody>
                        {waitlist.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Name}</td>
                                <td>{item.Item}</td>
                                <td>{item.CreamColor}</td>
                                <td>{readableDate(item.TimeAdded)}</td>
                                <td>
                                    <Button variant="outline-success" onClick={() => handleActionClick(item, 'seat')}>Seat</Button>{' '}
                                    <Button variant="outline-primary" onClick={() => handleActionClick(item, 'togo')}>To Go</Button>{' '}
                                    <Button variant="outline-danger" onClick={() => handleActionClick(item, 'delete')}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Waitlist;
